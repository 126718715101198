import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type MetaItem = {
    name: string,
    content: string
}
type SeoProps = {
    title: string
    description?: string,
    url?: string,
    author?: string,
    keywords?: string[],
    lang?: string,
    image?: string,
    meta?: MetaItem[],
}
const Seo: React.FC<SeoProps> = (props) => {

    const { site: { siteMetadata } } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    lang
                    description
                    author
                    url
                    keywords
                    image
                }
            }
        }
    `)

    const title = props.title
    const lang = props.lang || siteMetadata.lang
    const description = props.description || siteMetadata.description
    const author = props.author || siteMetadata.author
    const url = props.url || siteMetadata.url
    const image = props.image || siteMetadata.image
    const keywords = [...siteMetadata.keywords, props.keywords].join(',')
    const meta = [
        {
            name: 'canonical',
            content: url,
        },
        {
            name: 'description',
            content: description,
        },
        {
            property: 'image',
            content: image,
        },
        {
            property: 'keywords',
            content: keywords,
        },
        {
            property: 'og:url',
            content: url,
        },
        {
            property: 'og:title',
            content: title,
        },
        {
            property: 'og:description',
            content: description,
        },
        {
            property: 'og:image',
            content: image,
        },
        {
            property: 'og:type',
            content: 'webiste',
        },
        {
            name: 'twitter:card',
            content: 'summary',
        },
        {
            name: 'twitter:creator',
            content: author,
        },
        {
            name: 'twitter:title',
            content: title,
        },
        {
            name: 'twitter:description',
            content: description
        },
    ]
    const metadata = (siteMetadata.meta) ?
        meta.concat(siteMetadata.meta) :
        meta
    

    const link = [
        {
            rel: 'shortcut icon',
            href: 'favicon.ico',
        },
        {
            rel: 'apple-touch-icon',
            href: 'icons/apple-touch-icon.png',
        },
    ]    

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={ title }
            titleTemplate={ `%s | ${ siteMetadata.title }` }
            meta={ metadata }
            link={ link }
        />
    )
}

export default Seo